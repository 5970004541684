import { Row, Image, Col } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMatches } from '../../store/reducers/session';
import TwitchLogo from '../resources/images/Twitch-Logo.png';
import { getMatchDetails } from '../utils/misc';

const MatchView = () => {
  const dispatch = useDispatch();

  const { matchCollection, loading } = useSelector(state => state.data);
  const { id } = useParams();

  useEffect(() => {
    if (!matchCollection && !loading) {
      dispatch(fetchMatches());
    }
  }, [matchCollection, loading, dispatch]);

  const match = matchCollection?.find(({ urlSuffix }) => urlSuffix === id) || {};

  const { team1, team2, result1, result2, matchTime1, matchTime2 } = getMatchDetails(match);

  return !matchCollection ? (
    <div>Loading...</div>
  ) : (
    <div className="align-items-center justify-content-center">
      <Col sm={8} className="container">
        <Row className="super-box">
          <span className="l-w">
            <Row>
              <div class="col text-left">
                <Row>
                  <div class="col text-start">
                    <span class="ms-2">{team1}</span>
                  </div>
                </Row>
                <Row>
                  <div class="col text-start">
                    <span class="ms-2">{team2}</span>
                  </div>
                </Row>
              </div>
              <div class="col text-right">
                <Row>
                  <div class="col text-end">
                    <span className="ms-2">{match?.result ? result1 : matchTime1}</span>
                  </div>
                </Row>
                <Row>
                  <div class="col text-end">
                    <span className="ms-2">{match?.result ? result2 : matchTime2}</span>
                  </div>
                </Row>
              </div>
            </Row>
          </span>
          <div
            className="mb-5 mt-5"
            dangerouslySetInnerHTML={{
              __html: match?.result ? match.afterMatch : match?.description,
            }}
          />
          {match?.twitchLink ? (
            <a href={match.twitchLink} target="_blank" rel="noreferrer">
              <Image className="img-logo ml-5" src={TwitchLogo} />
            </a>
          ) : null}
        </Row>
      </Col>
    </div>
  );
};

export default MatchView;
