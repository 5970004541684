import { Image } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfiles } from '../../store/reducers/session';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { generateSocialMediaArray } from './misc';

const StreamerMap = () => {
  const dispatch = useDispatch();
  const { profileCollection, loading } = useSelector(state => state.data);

  useEffect(() => {
    if (!profileCollection && !loading) {
      dispatch(fetchProfiles());
    }
  }, [profileCollection, loading, dispatch]);

  const mapStreamers = profiles => {
    return profiles
      ?.filter(({ isStreamer }) => isStreamer)
      .map(({ profile, profilePicture }) => {
        const socialMedia = generateSocialMediaArray(profile);

        return (
          <Col sm={8}>
            <div className="image-container p-3 profile-box bg-black">
              <Link to={'/profile/' + profile.nickname}>
                <span className="image-label p-3 custom-h2 bg-black" style={{ '--bs-bg-opacity': 0.5 }}>
                  {profile?.nickname}
                </span>
                <Image className="rounded img box-shadow" src={profilePicture?.url} alt={profile?.nickname} />
              </Link>
            </div>
            <div className="gap-2 d-flex justify-content-center">
              {socialMedia.map(platform =>
                platform.link ? (
                  <a key={platform.link} href={platform.link} target="_blank" rel="noreferrer">
                    {platform.icon}
                  </a>
                ) : null
              )}
            </div>
          </Col>
        );
      });
  };

  return mapStreamers(profileCollection);
};

export default StreamerMap;
