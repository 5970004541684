import React from "react";
import Home from "./common";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./common/home/components/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "./common/styles/App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
