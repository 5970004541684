import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import dataSlice from "./reducers/session";
const store = configureStore({
  reducer: { data: dataSlice },
  middleware: [thunk],
});

export default store;
