import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';

const Paginator = ({ data = [], size = 3, formatfunc, classStyle, title = '', hidePage = false, useScroll = true }) => {
  const [currentPage, setCurrentcurrentPage] = useState(0);
  const numberOfPages = Math.ceil(data.length / size);
  const start = currentPage * size;
  const end = (currentPage + 1) * size;
  const paginatedData = formatfunc(data.slice(start, end));

  const topElementRef = useRef(null);

  const handlePageChange = page => {
    setCurrentcurrentPage(page);
    if (useScroll) {
      topElementRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }
  };

  return (
    <React.Fragment>
      <Row ref={topElementRef}>
        <Col xs={1} sm={1} className="justify-content-right d-grid">
          {currentPage > 0 && (
            <button
              className="paginator-button display-6"
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ color: 'orange' }}
            >
              {'<'}
            </button>
          )}
        </Col>
        <Col xs={10} sm={10} className={classStyle}>
          {title ?? <h2 className="text-start custom-h2">{title}</h2>}
          {paginatedData}
        </Col>
        <Col xs={1} sm={1} className="justify-content-right d-grid">
          {currentPage < numberOfPages - 1 && (
            <button
              className="paginator-button display-6"
              onClick={() => handlePageChange(currentPage + 1)}
              style={{ color: 'orange' }}
            >
              {'>'}
            </button>
          )}
        </Col>
      </Row>
      {!hidePage && (
        <Row className="justify-content-center">
          {currentPage + 1} / {numberOfPages}
        </Row>
      )}
    </React.Fragment>
  );
};

export default Paginator;
