import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import milli_logo from '../resources/images/milli_logo.png';
import grolls_logo from '../resources/images/grolls.png';
import osirion_logo from '../resources/images/osirionPNG.png';
import './ContactView.css';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';

const ContactView = () => {
  return (
    <div className="container mt-5">
      <h1 className="justify-content-center d-grid custom-h1">Yhteistyökumppanit</h1>
      <Row>
        <Col sm={4} className="align-self-center">
          <Image className="img rounded" src={milli_logo} alt="Millibaari logo" />
        </Col>
        <Col sm={8}>
          <br />
          Millibaari on Vehmersalmella sijaitseva kesäravintola joka on mukana mahdollistamassa Esports toimintaamme.
          <br />
          Millibaari palvelee niin janoista kuin nälkäistäkin kulkijaa!
          <br />
          <br />
          <a href="https://www.millibaari.fi" className="no-underline" target="_blank" rel="noreferrer">
            www.millibaari.fi
          </a>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={4} className="align-self-center">
          <Image className="img rounded" src={grolls_logo} alt="grolls logo" />
        </Col>
        <Col sm={8}>
          <br />
          GROLLS on työvaatteiden ja suojaimien asiantuntija!
          <br />
          NLEshopin tuotteet valmistuvatkin ammattitaidolla suoraan savon syrämestä!
          <br />
          Jos kaipaat asiantuntevaa ja mutkatonta palvelua, on GROLLS juuri oikea valinta!
          <br />
          <br />
          <a href="https://www.grolls.fi" className="no-underline" target="_blank" rel="noreferrer">
            www.grolls.fi
          </a>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className="align-self-center">
          <Image className="img rounded" src={osirion_logo} alt="osirion logo" />
        </Col>
        <Col sm={8}>
          <br />
          Osirion on tekoälyvalmentaja Fortnite-pelaajille. Osirion tarjoaa myös työkaluja striimaajille ja on
          edelläkävijä tekoälyn käytössä Fortnite-valmennuksessa! Rekisteröityessä käytä koodia NLEFI !
          <br />
          <br />
          <a href="https://www.osirion.gg/" className="no-underline" target="_blank" rel="noreferrer">
            www.osirion.gg
          </a>
        </Col>
      </Row>
      <Row className="text-center mt-5">
        <Link className="no-underline" to="/marketing">
          <h2 className="custom-h2">
            Haluatko yhteistyökumppaniksi? <br />
            Kiinnostaako näkyvyys NLE:n toiminnassa?
          </h2>
        </Link>
      </Row>
    </div>
  );
};

export default ContactView;
