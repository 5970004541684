import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { FaEnvelope, FaTwitterSquare, FaInstagram, FaYoutube, FaTiktok, FaTelegram } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactView = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const emailAddress = 'info@nlefi.gg';

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormData({
      name: '',
      email: '',
      message: '',
    });

    emailjs.init('aNB6-FENdZx0DzeuG');
    const serviceId = 'service_nq5kq4n';
    emailjs.sendForm(serviceId, 'template_mucg1vq', e.target).then(
      () => {
        toast.success('Yhteydenottosipyyntösi on lähetetty', {
          position: 'top-right',
          autoClose: 3000, // Auto-close the toast after 3 seconds (adjust as needed)
          hideProgressBar: false,
        });
      },
      error => {
        console.error('Error sending email:', error.text);
      }
    );
  };

  const socialMediaLinks = [
    { name: 'Twitter', url: 'https://twitter.com/northern_legacy', icon: <FaTwitterSquare /> },
    { name: 'Instagram', url: 'https://www.instagram.com/northernlegacyesports', icon: <FaInstagram /> },
    { name: 'YouTube', url: 'https://www.youtube.com/channel/UCNl0a-dyVxjajE0xUJAXQug', icon: <FaYoutube /> },
    { name: 'TikTok', url: 'https://www.tiktok.com/@northernlegacyesports', icon: <FaTiktok /> },
    { name: 'Telegram', url: 'https://t.me/NLE_TG', icon: <FaTelegram /> },
  ];

  const handleRecaptchaChange = async value => {
    setRecaptchaValue(value);
  };

  return (
    <div className="container mt-5">
      <h1>Ota yhteyttä</h1>
      <Row className="justify-content-center">
        <Col md={3} className="mb-4">
          <p>Voit laittaa viestiä whatsupin, sähköpostin, sosiaalisten medioiden tai allaolevan lomakkeen kautta!</p>
        </Col>
        <Col md={3} className="mb-4">
          <div className="mt-3">
            <a href={`mailto:${emailAddress}`} className="no-underline" style={{ color: 'orange' }}>
              <FaEnvelope /> {emailAddress}
            </a>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6} className="mb-4">
          <div className="d-flex justify-content-between flex-wrap">
            {socialMediaLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noreferrer"
                className="mb-3 mr-3 no-underline"
                style={{ color: 'orange' }}
              >
                {link.icon} {link.name}
              </a>
            ))}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Form onSubmit={handleSubmit} className="w-50">
          <Form.Group controlId="name">
            <Form.Label>Nimi:</Form.Label>
            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Sähköposti:</Form.Label>
            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Label>Viesti:</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              required
            />
          </Form.Group>
          {!recaptchaValue && (
            <ReCAPTCHA className="mt-2" sitekey={process.env.REACT_APP_CAPTCHA_KEY} onChange={handleRecaptchaChange} />
          )}
          {recaptchaValue && (
            <Button
              type="submit"
              className="mt-2 box-shadow"
              color="none"
              style={{
                borderColor: 'orange',
                color: 'orange',
                backgroundColor: 'black',
              }}
            >
              Lähetä
            </Button>
          )}
        </Form>
      </Row>
    </div>
  );
};

export default ContactView;
