import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTeams } from '../../store/reducers/session';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';

const mapEsports = teams => {
  return teams.map(team => (
    <div className="image-container p-3 box-shadow bg-black">
      <Link to={'/teams/' + team?.urlPrefix}>
        <Image className="rounded img" src={team?.image?.url} alt={team.game} />
      </Link>
    </div>
  ));
};

export default function RosterView() {
  const dispatch = useDispatch();
  const { teamsCollection, loading } = useSelector(state => state.data);

  useEffect(() => {
    if (!teamsCollection && !loading) {
      dispatch(fetchTeams());
    }
  }, [teamsCollection, loading, dispatch]);

  return (
    <div className="container mt-5">
      <h1 className="custom-h1">Esports</h1>
      <Row>
        <Col sm={6}>
          <span>
            NLE on kilpapelaamiseen keskittynyt organisaatio. Haemme aktiivisesti tapoja toimia paremmin ja
            tehokkaammin, tavoitteenamme nousta Pro-skeneen mukaan. Edustusjoukkuiden kehittäminen ja menestyminen on
            meille tärkeää.
          </span>
        </Col>
      </Row>
      <Row>
        {teamsCollection && (
          <div className="text-center">
            <div className="teams-container">
              {mapEsports(teamsCollection.filter(t => t.membersCollection.items.length > 0))}
            </div>
          </div>
        )}
      </Row>
    </div>
  );
}
