import {
  FaYoutube,
  FaInstagram,
  FaTwitch,
  FaTwitter,
  FaFacebook,
  FaTiktok,
  FaDiscord,
  FaTelegram,
} from 'react-icons/fa';
import eseaLogo from '../resources/images/ESSULogo.png';
import fortniteLogo from '../resources/images/fortnite.jpg';
import fortniteLogo2 from '../resources/images/forttilogo.png';
import cs2logo from '../resources/images/cs2logo.png';

const options = {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const parseMatchTime = time => {
  return new Date(time).toLocaleString('fi', options).split(' ');
};

export const getMatchDetails = match => {
  const teams = (match?.title || '').split('-');
  const team1 = teams?.[0];
  const team2 = teams?.[1];

  const hasResult = match?.result;
  const result = hasResult ? match?.result.split('-') : [];
  const result1 = result?.[0];
  const result2 = result?.[1];

  const matchTime = parseMatchTime(match?.matchTime);
  const matchTime1 = matchTime?.[0];
  const matchTime2 = matchTime?.[1];

  return {
    team1,
    team2,
    result1,
    result2,
    matchTime1,
    matchTime2,
  };
};

export const generateSocialMediaArray = profile => [
  { platform: 'YouTube', icon: <FaYoutube />, link: profile?.youtube },
  { platform: 'Instagram', icon: <FaInstagram />, link: profile?.ig },
  { platform: 'Twitch', icon: <FaTwitch />, link: profile?.twitch },
  { platform: 'Twitter', icon: <FaTwitter />, link: profile?.twitter },
  { platform: 'Facebook', icon: <FaFacebook />, link: profile?.facebook },
  { platform: 'TikTok', icon: <FaTiktok />, link: profile?.tiktok },
  { platform: 'Discord', icon: <FaDiscord />, link: profile?.discord },
  { platform: 'Telegram', icon: <FaTelegram />, link: profile?.telegram },
];

export const gameMap = {
  fortnite: fortniteLogo2,
  csgo: cs2logo,
};

export const leagueMap = {
  esea: {
    url: 'https://www.faceit.com/fi/cs2/league/ESEA%20League/a14b8616-45b9-4581-8637-4dfd0b5f6af8/ddc31c1e-7392-4859-a509-dd98fdbb824e/standings',
    logo: eseaLogo,
  },
  fortnite: {
    url: 'https://www.fortnite.com/@nlefi',
    logo: fortniteLogo,
  },
};
