import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { gql } from 'graphql-tag';

const headers = {
  Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
  'Content-Type': 'application/json',
};

const url = `https://graphql.contentful.com/content/v1/spaces/13urdxl5ext2/environments/master`;

const fetchData = createAsyncThunk('data/fetchData', async () => {
  const response = await axios.post(
    url,
    {
      query:
        '{newsCollection {  items {    date    id    title    content    shortDescription    image {      title      description      contentType      fileName      size      url      width      height    }  }}}',
    },
    { headers }
  );
  return response.data;
});
//todo change query
const fetchProfiles = createAsyncThunk('data/fetchProfiles', async () => {
  const response = await axios.post(
    url,
    {
      query:
        '{profileCollection {  items {    isStreamer    profile    profilePicture {      title      description      contentType      fileName      size      url      width      height    }  }}}',
    },
    { headers }
  );
  return response.data;
});

const fetchMatches = createAsyncThunk('data/fetchMatches', async () => {
  const query = gql`
    query FetchMatches {
      matchCollection(order: [matchTime_DESC]) {
        items {
          result
          afterMatch
          urlSuffix
          title
          description
          matchTime
          twitchLink
          t2Logo {
            url
          }
          t1Logo {
            url
          }
          league
        }
      }
    }
  `;

  const { data } = await axios.post(url, { query: query.loc.source.body }, { headers });

  return data;
});

const fetchTeams = createAsyncThunk('data/fetchTeams', async () => {
  const query = gql`
    {
      teamsCollection {
        items {
          urlPrefix
          game
          membersCollection(limit: 10) {
            items {
              profilePicture {
                url
              }
              isCoach
              profile
            }
          }
          image {
            url
          }
          leagues
        }
      }
    }
  `;
  const { data } = await axios.post(url, { query: query.loc.source.body }, { headers });
  return data;
});

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    newsCollection: undefined,
    loading: false,
    profileCollection: undefined,
    matchCollection: undefined,
    teamsCollection: undefined,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchData.pending, state => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.newsCollection = action.payload.data.newsCollection.items;
        state.loading = false;
      })
      .addCase(fetchData.rejected, state => {
        state.newsCollection = [];
        state.loading = false;
      })
      .addCase(fetchProfiles.pending, state => {
        state.loading = true;
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.profileCollection = action.payload.data.profileCollection.items;
        state.loading = false;
      })
      .addCase(fetchProfiles.rejected, state => {
        state.profileCollection = [];
        state.loading = false;
      })
      .addCase(fetchMatches.pending, state => {
        state.loading = true;
      })
      .addCase(fetchMatches.fulfilled, (state, action) => {
        state.matchCollection = action.payload.data.matchCollection.items;
        state.loading = false;
      })
      .addCase(fetchMatches.rejected, state => {
        state.matchCollection = [];
        state.loading = false;
      })
      .addCase(fetchTeams.pending, state => {
        state.loading = true;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.teamsCollection = action.payload.data.teamsCollection.items;
        state.loading = false;
      })
      .addCase(fetchTeams.rejected, state => {
        state.teamsCollection = [];
        state.loading = false;
      });
  },
});

export { fetchData, fetchProfiles, fetchMatches, fetchTeams };
export default dataSlice.reducer;
