import { Col, Row, Image } from 'react-bootstrap';
import React from 'react';
import logouusi_esl from '../resources/images/logouusi_esl.png';

const Footer = () => {
  return (
    <div className="footer mt-2">
      <div className="container mb-5 mt-5">
        <Row>
          <Col sm={6} className="footer-box phone-center">
            <Image className="footer-logo" src={logouusi_esl} />
          </Col>
          <Col sm={6} className="phone-center">
            <span>Gaming Legacy Oy</span>
            <br />
            <span>Y-tunnus 3308118-5</span>
            <br />
            <span>info@nlefi.gg</span>
            <br />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
