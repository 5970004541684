import { Col, Image, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTeams } from '../../store/reducers/session';
import { Link } from 'react-router-dom';
import { leagueMap } from '../utils/misc';

const TeamView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const mapPlayers = profiles => {
    return profiles.map(({ profile, profilePicture }) => (
      <Col className="image-container profile-box p-3 bg-black">
        <span className="image-label p-3 custom-h2 bg-black" style={{ '--bs-bg-opacity': 0.5 }}>
          {profile?.nickname}
        </span>
        <Link to={'/profile/' + profile?.nickname}>
          <Image className="rounded img bg-black box-shadow" src={profilePicture.url} alt={profile?.nickname} />
        </Link>
      </Col>
    ));
  };

  const { teamsCollection, loading } = useSelector(state => state.data);

  useEffect(() => {
    if (!teamsCollection && !loading) {
      dispatch(fetchTeams());
    }
  }, [teamsCollection, loading, dispatch]);

  const team = teamsCollection?.find(team => team?.urlPrefix === id) || {};

  return !teamsCollection ? (
    <div>Loading...</div>
  ) : (
    <div className="container">
      <Row>
        <Col sm={6} className="custom-order-2 justify-content-center">
          <div className="d-grid center-in-mobile">
            {mapPlayers(team?.membersCollection?.items.filter(profile => profile?.isCoach))}
          </div>
        </Col>
        <Col sm={4} className="custom-order">
          <Row>
            <Image src={team?.image?.url} className="img" />
          </Row>
          <div className="league-container">
            {team?.leagues &&
              team?.leagues.map((league, index) => (
                <div className="league" key={index}>
                  <a href={leagueMap[league].url} target="_blank" rel="noreferrer">
                    <Image src={leagueMap[league].logo} className="img" />
                  </a>
                </div>
              ))}
          </div>
        </Col>
      </Row>
      <Row>
        <div className="player-container">
          {mapPlayers(team?.membersCollection?.items.filter(profile => !profile?.isCoach))}
        </div>
      </Row>
    </div>
  );
};

export default TeamView;
