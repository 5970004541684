import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeView from './home';
import Main from './home/Main';
import NewsView from './home/NewsView';
import ProfileView from './profile';
import RosterView from './roster';
import EsportsView from './esports/EsportsView';
import TeamView from './profile/TeamView';
import MatchView from './esports/MatchView';
import ContactView from './contact/ContactView';
import SponsorView from './contact/SponsorView';
import Marketing from './contact/Marketing';
import InfoView from './info/InfoView';

const Home = () => {
  const routes = () => (
    <Routes>
      <Route path="profile/:id" element={<ProfileView />} />
      <Route path="roster/*" element={<RosterView />} />
      <Route path="esports/*" element={<EsportsView />} />
      <Route path="match/:id" element={<MatchView />} />
      <Route path="contact/*" element={<ContactView />} />
      <Route path="sponsors/*" element={<SponsorView />} />
      <Route path="info/*" element={<InfoView />} />
      <Route path="news/:id" element={<NewsView />} />
      <Route path="teams/:id" element={<TeamView />} />
      <Route path="marketing/" element={<Marketing />} />
      <Route path="/*" element={<Main />} />
    </Routes>
  );
  return <HomeView routes={routes()} />;
};

export default Home;
