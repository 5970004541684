import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import milli_logo from '../resources/images/milli_logo.png';
import grolls_logo from '../resources/images/grolls.png';
import streamerlogo from '../resources/images/streamerlogo.png';
import cslogo from '../resources/images/cs2logo.png';
import { Row, Col, Image } from 'react-bootstrap';
import './ProfileView.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfiles } from '../../store/reducers/session';
import VideoPlayer from '../home/components/VideoPlayer';
import { Link } from 'react-router-dom';
import { generateSocialMediaArray, gameMap } from '../utils/misc';

export default function ProfileView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { profileCollection, loading } = useSelector(state => state.data);

  useEffect(() => {
    if (!profileCollection && !loading) {
      dispatch(fetchProfiles());
    }
    setTimeout(() => {}, 100);
  }, [profileCollection, loading, dispatch]);

  const { profile, profilePicture, isStreamer } =
    profileCollection?.find(({ profile }) => profile?.nickname === id) || {};
  const { bio, nickname, videoUrl1, videoUrl2, videoUrl3, name, game } = profile || {};
  const socialMedia = generateSocialMediaArray(profile);

  return !profileCollection ? (
    <div>Loading...</div>
  ) : (
    <div className="container mt-5">
      <Row className="border-bothrefm-5">
        <div className="d-flex flex-row-reverse gap-2 px-md-5">
          <Link className="no-underline" to="/marketing">
            Yhteistyöt ja logopaikat
          </Link>
          <Link className="no-underline" to="/contact">
            Ota yhteyttä
          </Link>
        </div>
      </Row>
      <Row>
        <Col sm={3}>
          <div className="responsive-col">
            <img className="rounded img responsive-image" src={profilePicture?.url} alt={nickname} />
          </div>
        </Col>
        <Col>
          <Row>
            <h1 className="d-flex flex-row custom-h1 mb-0">{nickname}</h1>
            <h2 className="d-flex flex-row custom-h1 text-muted mt-0">{name}</h2>
          </Row>
          <Row>
            <div
              dangerouslySetInnerHTML={{
                __html: bio,
              }}
            />
          </Row>
          <Row className="mt-2">
            <Col className="some-container">
              {socialMedia.map(platform =>
                platform.link ? (
                  <a key={platform.platform} href={platform.link} target="_blank" rel="noreferrer">
                    {platform.icon}
                  </a>
                ) : null
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={2} className="d-flex flex-column justify-content-end">
          <div className="responsive-col">
            <Image className="img-fluid responsive-image" src={isStreamer ? streamerlogo : gameMap[game] || cslogo} />
          </div>
        </Col>
      </Row>
      <Row className="mt-3 custom-order-3">
        <div className="video-container">
          {videoUrl1 && (
            <Col>
              <VideoPlayer src={videoUrl1} />
            </Col>
          )}
          {videoUrl2 && (
            <Col>
              <VideoPlayer src={videoUrl2} />
            </Col>
          )}
          {videoUrl3 && (
            <Col>
              <VideoPlayer src={videoUrl3} />
            </Col>
          )}
        </div>
      </Row>
      <Row className="justify-content-center">
        <Col sm={4} className="text-center">
          <img className="img" src={milli_logo} alt="Millibaari logo" />
          <img className="img" src={grolls_logo} alt="Grolls logo" />
        </Col>
      </Row>
    </div>
  );
}
