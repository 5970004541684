import React from "react";
import HoverVideoPlayer from "react-hover-video-player";

const VideoPlayer = (src) => {
  return (
    <HoverVideoPlayer
      videoStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
      videoSrc={src}
      controls
      loadingOverlay={
        <div className="loading-overlay">
          loadingOverlay={<div className="loading-overlay">Loading...</div>}
        </div>
      }
    />
  );
};

export default VideoPlayer;
