import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './RosterView.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProfiles } from '../../store/reducers/session';
import { Link } from 'react-router-dom';
import { generateSocialMediaArray } from '../utils/misc';

const mapStreamers = profiles => {
  const thisYear = new Date().getFullYear();
  return profiles
    .filter(({ isStreamer }) => isStreamer)
    .map(({ profile, profilePicture }) => {
      const socialMedia = generateSocialMediaArray(profile);
      const { age, location, type, strength, motto, nickname } = profile;
      return (
        <Row key={profile.nickname} className="ml-5 mr-5">
          <Col sm={6}>
            <div className="image-container p-3 profile-box">
              <Link to={'/profile/' + profile.nickname}>
                <Image
                  className="rounded img box-shadow align-self-center responsive-image"
                  src={profilePicture.url}
                  alt={profile.nickname}
                />
              </Link>
            </div>
          </Col>
          <Col className="text-end" sm={6}>
            <h2 className="custom-h2">{nickname}</h2>
            <span>{type}</span>
            <ul className="list-unstyled">
              {age > 1900 ? <li>Ikä: {thisYear - age}</li> : null}
              <li>Paikkakunta: {location}</li>
              <li>Vahvuus: {strength}</li>
              <li>Motto: {motto}</li>
            </ul>
            <div className="gap-2 d-flex justify-content-center">
              {socialMedia.map(platform =>
                platform.link ? (
                  <a key={platform.platform} href={platform.link} target="_blank" rel="noreferrer">
                    {platform.icon}
                  </a>
                ) : null
              )}
            </div>
          </Col>
        </Row>
      );
    });
};

export default function RosterView() {
  const dispatch = useDispatch();
  const { profileCollection, loading } = useSelector(state => state.data);

  useEffect(() => {
    if (!profileCollection && !loading) {
      dispatch(fetchProfiles());
    }
  }, [profileCollection, loading, dispatch]);

  return (
    <div className="container mt-5 d-grid">
      <Row className="mb-3">
        <h1 className="custom-h1">Sometalli</h1>
        <Col sm={5}>
          <span>
            Northern Legacy on organisaationa monessa mukana. Allamme toimii niin kilpaurheilijoita kuin myös
            esimerkiksi somevaikuttajia. Toimintamme perimmäisenä tarkoituksena on tukea ja kehittää yksilöitä, löytää
            lahjakkuuksia ja manageroida jäseniämme.
          </span>
        </Col>
      </Row>
      <Row sm={3}>
        <ul style={{ listStylePosition: 'inside' }}>
          <li>Henkilökohtaista tukea</li>
          <li>Graafisia palveluita</li>
          <li>Yhteisöllisyyttä</li>
          <li>Näkyvyyttä</li>
          <li>Urasuunnittelua</li>
          <li>Yhteistöitä</li>
        </ul>
      </Row>
      <Row>{profileCollection && <div className="streamer-container">{mapStreamers(profileCollection)}</div>}</Row>
    </div>
  );
}
