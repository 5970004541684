import { Row, Col, Image } from 'react-bootstrap';
import React, { useEffect } from 'react';
import logouusi_esl from '../resources/images/logouusi_esl.png';
import discord_logo from '../resources/images/Discord-Logo.png';
import introVideo from '../resources/videos/NLE_main.mp4';
import valcoVideo from '../resources/videos/valco.mp4';
import './Main.css';
import '../resources/fonts/Zaychik-Regular.ttf';
import '../resources/fonts/pirulen.ttf';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, fetchMatches } from '../../store/reducers/session';
import { Link } from 'react-router-dom';
import Paginator from './components/Paginator';
import StreamerMap from '../utils/StreamerMap.js';
import { mapNews } from '../utils/MatchMap';

const Main = () => {
  const dispatch = useDispatch();
  const { newsCollection, matchCollection, loading } = useSelector(state => state.data);

  useEffect(() => {
    if (!newsCollection && !loading) {
      dispatch(fetchData());
    }
    if (!matchCollection && !loading) {
      dispatch(fetchMatches());
    }
  }, [matchCollection, newsCollection, loading, dispatch]);

  return (
    <div className="container">
      <Row>
        <video autoPlay muted>
          <source src={introVideo} type="video/mp4" />
        </video>
      </Row>
      <Row className="mb-5">
        <Col className="text-center justify-content-center">
          <span>
            NLE on Suomalainen organisaatio. Allamme toimivat yksilöt luovat kokonaisuuden jossa voimme olla ylpeästi
            NLE:läisiä !
            <br />
            <Link className="no-underline" style={{ color: 'orange' }} to="/contact">
              Hyppää mukaan!
            </Link>
          </span>
          <br />
          <h2 className="mt-2">#VÄHEMMÄNNÖYRÄ #NLE #MENNÄÄNMENNÄÄN #SEVIRENAAMALLE</h2>
        </Col>
      </Row>
      <Row>
        <h2 className="custom-h2">Uutiset</h2>
        {newsCollection && (
          <div>
            <Paginator data={newsCollection} formatfunc={mapNews} classStyle="news-container gap-3" />
          </div>
        )}
      </Row>
      <Row>
        <div className="d-grid text-center mt-2">
          <h3 className="w-50 mx-auto text-center d-flex justify-content-center">
            <a href="https://discord.gg/jN7UNWy">
              <Image style={{ width: '25%' }} src={logouusi_esl} />
              <Image style={{ width: '75%' }} src={discord_logo} />
            </a>
          </h3>
          <span>
            Discordissamme on paljon vapaassa käytössä olevia kanavia, joista voit löytää itsellesi peliseuraa
            päivittäin!
            <br />
            Kanavia löytyy myös vapaalle keskustelulle, sekä kanavia mitä kautta voit pyytää apua.
          </span>
          <h2>#YHTEISÖ #NLE #LAUMA</h2>
        </div>
      </Row>
      <Row>
        <h2 className="custom-h2">Lauma</h2>
        <div className="streamer-container">
          <StreamerMap />
        </div>
      </Row>
      <Row>
        <div className="d-grid text-center mt-4">
          <h2 className="custom-h2">Ota NLE mukaan markkinointiisi !</h2>
          <span>
            Olitpa sitten markkinoimassa tuotetta tai vaikkapa palvelua, olemme oiva kumppani näkyvyyden ja
            tunnettavuuden lisäämisessä!
            <br />
            Kanssamme onnistuvat niin perinteiset logomarkkinoinnit kuin myös syvemmät yhteistyöt tavoitteellisesti.
          </span>
        </div>
      </Row>
      <Row className="mt-3" style={{ borderRadius: '20px' }}>
        <video autoPlay loop muted>
          <source src={valcoVideo} type="video/mp4" />
        </video>
      </Row>
    </div>
  );
};

export default Main;
