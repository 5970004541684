import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const CountdownTimer = ({ matchCollection }) => {
  const currentDate = new Date();
  const closestFutureMatch = matchCollection
    .map(match => ({ ...match, matchTime: new Date(match.matchTime) }))
    .filter(match => match.matchTime > currentDate)
    .sort((a, b) => a.matchTime - b.matchTime)
    .find(() => true); // Find the first element (closest future match)
  const targetDate = closestFutureMatch?.matchTime || currentDate;
  const [remainingTime, setRemainingTime] = useState(_.subtract(new Date(targetDate), new Date()));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(_.subtract(new Date(targetDate), new Date()));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const formatTime = time => _.padStart(Math.floor(time), 2, '0');
  const days = formatTime(remainingTime / (1000 * 60 * 60 * 24));
  const hours = formatTime((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = formatTime((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = formatTime((remainingTime % (1000 * 60)) / 1000);

  if (!closestFutureMatch) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <div className="overflow-x-disable w-100 text-center" style={{ backgroundColor: 'darkorange', color: 'white' }}>
      <span className="custom-h2" style={{ color: 'white' }}>
        <Link className="no-underline no-hover" to={'/match/' + closestFutureMatch?.urlSuffix}>
          Seuraava ottelu: {days}d {hours}h {minutes}m {seconds}s
        </Link>
      </span>
    </div>
  );
};

export default CountdownTimer;
